<script setup lang="ts">
const { target, isOpen, close } = useLoginModal()

const { escape } = useMagicKeys()

watch(escape, () => {
  isOpen.value && close()
})
</script>
<template>
  <Teleport to="body">
    <div v-if="isOpen" ref="target" class="modal modal-login">
      <div class="background" @click="close" />
      <div class="content">
        <button class="close" @click="close">
          <Icon name="large-close" />
        </button>
        <ShopLoginForm @close="close" @success="close" />
      </div>
    </div>
  </Teleport>
</template>
<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: var(--shadow);

  z-index: 101;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.content {
  position: relative;
  width: min(600px, 100%);

  @include fluid(padding, 32px, 50px);
  margin-block: 100px;
  background-color: var(--c-white);
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
</style>
